<ng-container *ngIf="this.popupData">
    <!-- GENERIC -->
    <div *ngIf="popupData.action == 'generic'">
        <div mat-dialog-content [innerHTML]="popupData.label"></div>
        <div mat-dialog-actions>
            <button mat-button mat-stroked-button mat-dialog-close cdkFocusInitial
                (click)="submitUserChoice(true, popupData.action,'', popupData.kind, popupData.data)">Chiudi</button>
        </div>
    </div>
    <!-- ACTION: LOADING + FARMADATI-->
    <div *ngIf="popupData.action == 'noresults' || popupData.action == 'open' || popupData.action == 'duplicateAic'">

        <h1 mat-dialog-title>Caricamento dati da Farmadati</h1>

        <div mat-dialog-content>

            <section>
                <div *ngIf="popupData.action == 'open' else message"
                    class="d-flex align-items-center justify-content-between">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <ng-template #message>
                    <p>{{popupData?.label}}</p>
                </ng-template>
            </section>
        </div>
        <div mat-dialog-actions>
            <button *ngIf="popupData.action == 'open'" mat-button mat-stroked-button
                (click)="submitUserChoice(true)">Annulla</button>
            <button *ngIf="popupData.action == 'noresults'" mat-button mat-dialog-close
                mat-stroked-button>Chiudi</button>
            <div *ngIf="popupData.action == 'duplicateAic'">
                <button mat-button mat-stroked-button
                    (click)="submitUserChoice(true, popupData.action,'', popupData.kind)">Si</button>
                <button mat-button mat-dialog-close mat-stroked-button
                    (click)="submitUserChoice(false, popupData.action,'', popupData.kind)">No</button>
            </div>
        </div>
    </div>
    <!-- ACTION: CREATE -->
    <div *ngIf="popupData.action == 'create'">
        <div *ngIf="popupData.kind == 'loading'">
            <h1 mat-dialog-title>Operazione in corso</h1>
            <div mat-dialog-content>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
        <div mat-dialog-content *ngIf="popupData.kind != 'loading'">
            <p>Il seguente elemento è stato creato correttamente:</p>
            <p>{{popupData?.label}}</p>
        </div>
        <div mat-dialog-actions *ngIf="popupData.kind != 'loading'">
            <button mat-button mat-stroked-button mat-dialog-close cdkFocusInitial
                (click)="submitUserChoice(true, popupData.action,'', popupData.kind)">OK</button>
        </div>
    </div>
    <!-- ACTION: UPDATE -->
    <div *ngIf="popupData.action == 'update'">
        <div *ngIf="popupData.kind == 'loading'">
            <h1 mat-dialog-title>Operazione in corso</h1>
            <div mat-dialog-content>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
        <div mat-dialog-content *ngIf="popupData.kind != 'loading'">
            <p>Il seguente elemento è stato aggiornato correttamente:</p>
            <p>{{popupData?.label}}</p>
        </div>
        <div mat-dialog-actions *ngIf="popupData.kind != 'loading'">
            <button mat-button mat-stroked-button mat-dialog-close cdkFocusInitial
                (click)="submitUserChoice(true, popupData.action,'', popupData.kind)">OK</button>
        </div>
    </div>
    <!-- ACTION: ALERT -->
    <div *ngIf="popupData.action == 'alert'">
        <div *ngIf="popupData.kind == 'merc'">
            <h1 mat-dialog-title>Attenzione</h1>

            <div mat-dialog-content>
                <h4>Modificando la categoria merceologica, perderai tutte le informazioni inserite per gli Articoli</h4>
            </div>

        </div>
        <div mat-dialog-actions>
            <button mat-button mat-stroked-button mat-dialog-close cdkFocusInitial>Chiudi</button>
            <button mat-button mat-dialog-close mat-stroked-button
                (click)="submitUserChoice(true, popupData.action,'', popupData.kind)">OK</button>
        </div>
    </div>
    <!-- ACTION: DELETE -->
    <div *ngIf="popupData.action == 'delete'">
        <div *ngIf="popupData.kind == 'item'">
            <h1 mat-dialog-title>Elimina Elemento</h1>
            <div mat-dialog-content>
                <p>Vuoi eliminare il seguente elemento?</p>
                <p>{{popupData.label}}</p>
            </div>
        </div>
        <div *ngIf="popupData.kind == 'block'">
            <h1 mat-dialog-title>Elimina Righe</h1>
            <div mat-dialog-content>
                <p>Questo eliminerà definitivamente tutte le righe attualmente presenti. Procedere?</p>
            </div>
        </div>
        <div *ngIf="popupData.kind == 'delete_forever'">
            <h1 mat-dialog-title>Elimina Contenuto</h1>
            <div mat-dialog-content>
                <p>Questo eliminerà definitivamente il contenuto. Procedere?</p>
            </div>
        </div>
        <div *ngIf="popupData.kind == 'loading'">
            <h1 mat-dialog-title>Operazione in corso</h1>
            <div mat-dialog-content>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
        <div mat-dialog-actions *ngIf="popupData.kind != 'loading'">
            <button mat-button mat-stroked-button mat-dialog-close cdkFocusInitial>No</button>
            <button mat-button mat-dialog-close mat-stroked-button
                (click)="submitUserChoice(true, 'confirm_delete','',popupData.kind, popupData.data)">Si</button>
        </div>
    </div>
    <!-- ACTION: DUPLICATE -->
    <div *ngIf="popupData.action == 'duplicate'">
        <div *ngIf="popupData.kind == 'confirm'">
            <h1 mat-dialog-title>Duplica Elemento</h1>
            <div mat-dialog-content>
                <p>Vuoi duplicare il seguente elemento?</p>
                <p>{{popupData.label}}</p>
            </div>
        </div>

        <div mat-dialog-actions>
            <button mat-button mat-stroked-button mat-dialog-close cdkFocusInitial>No</button>
            <button mat-button mat-dialog-close mat-stroked-button
                (click)="submitUserChoice(true, 'confirm_duplicate','',popupData.kind, popupData.data)">Si</button>
        </div>
    </div>
    <!-- ERROR -->
    <div *ngIf="popupData.action == 'error'">
        <h1 mat-dialog-title>Errore</h1>
        <div mat-dialog-content>
            Si è verificato un errore:
            Errore:{{popupData.label.message}}
        </div>
        <div mat-dialog-actions>
            <button mat-button mat-stroked-button mat-dialog-close cdkFocusInitial
                (click)="submitUserChoice(true, popupData.action,'', popupData.kind, popupData.data)">Chiudi</button>
        </div>
    </div>
    <div *ngIf="popupData.action == 'view'">
        <h1 mat-dialog-title>Dettaglio Giacienza</h1>
        <div mat-dialog-content>
            <section class="border border-1 p-1 mb-4"
                *ngIf="popupData['mainData'] && popupData['mainData'].length != 0">
                <div class="row pb-2">
                    <div class="col d-flex align-items-center justify-content-between">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Rif Ordine</th>
                                    <th scope="col" class="text-center">N Lotto Fornitore</th>
                                    <th scope="col" class="text-center">Quantità</th>
                                    <th scope="col" class="text-center">Data Scadenza</th>
                                    <th scope="col" class="text-center">Data Elaborazione</th>
                                    <th scope="col" class="text-center">Codice Deposito</th>
                                    <th scope="col">Prezzo Acquisto</th>
                                    <!-- <th scope="col">Prezzo di Listino</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let choice of popupData['mainData']; let i=index">
                                    <th scope="row" class="text-center">{{choice['codice_interno']}}</th>
                                    <td class="text-center">{{choice['codice_fornitore']}}</td>
                                    <td class="text-end">{{choice['quantita']}}</td>
                                    <td class="text-center">{{choice['scadenza']}}</td>
                                    <td class="text-center">{{choice['data_elaborazione'] | date:'fullDate'}}</td>
                                    <td class="text-center">{{choice['codice_deposito']}}</td>
                                    <td>{{choice['prezzoAcquisto'] ? choice['prezzoAcquisto'] + ' €' : 'N/A'}}</td>
                                   <!-- <td>{{choice['prezzoListino']}} €</td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
        <div mat-dialog-actions>
            <button mat-button mat-stroked-button mat-dialog-close cdkFocusInitial
                (click)="submitUserChoice(true, popupData.action,'', popupData.kind, popupData.data)">Chiudi</button>
        </div>
    </div>
</ng-container>
import { Component, OnInit, HostListener } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.disableScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.disableScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.disableScroll(event);
  }



  currentUrl: string;

  constructor(
    public _router: Router,
    location: PlatformLocation,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        location.onPopState(() => {
          window.location.reload();
        });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }

      if (routerEvent instanceof NavigationEnd) {
        this.checkAndUpdateQueryParam();
      }

      window.scrollTo(0, 0);
    });
  }

  ngOnInit(): void {
    console.log('env: ' + environment.name);
    this.checkAndUpdateQueryParam();
    this.authService.currentUser.subscribe(u => {
      console.log(u)
      if (Object.keys(u).length === 0) {
        // if empty check for local Storage
        this.checkTokenLocale()
      }
    })
  }

  updateQueryParam() {
    const randomValue = Math.floor(Math.random() * 100000);
    this._router.navigate([], {
      queryParams: { v: randomValue },
      queryParamsHandling: 'merge'
    });
  }

  checkAndUpdateQueryParam() {
    // Recupera i parametri della query string all'avvio della pagina
    this.route.queryParams.subscribe(params => {
      if (!params['v']) { // Se il parametro 'ref' non esiste
        const randomValue = Math.floor(Math.random() * 100000); // Genera un valore casuale
        this._router.navigate([], {
          queryParams: { v: randomValue },
          queryParamsHandling: 'merge', // Mantiene gli altri parametri
          replaceUrl: true // Sostituisce l'URL nella cronologia senza aggiungere una nuova voce
        });
      }
    });
  }

  disableScroll(event: any) {
    if (event.srcElement.type === "number")
      event.preventDefault();
  }

  checkTokenLocale() {
    // check for a valid 
    if (window.localStorage.getItem('app_token') != null) {
      if (this.authService.isAuthenticated()){
        this.authService.setCurrentUserValue(JSON.parse(localStorage.getItem('app_token')))
        //this._router.navigate(['/app/main']);
      }
    }
  }


}
